import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ==============================|| MINIMAL LAYOUT ||============================== //
import christmas from 'assets/gif/christmas-sled-source_ulp.gif';

const MinimalLayout = () => {
  const isTokenAvailable = useSelector((state) => !!state.authenticationReducer?.loginData?.token);

  return (
    <>
      {isTokenAvailable && <Navigate to="main/newDashboard" replace={true} />}
      <Outlet />
      {/* <Customization /> */}
      <div>
        <img src={christmas} alt="Santa's Sleigh" className="santa-sleigh" />
      </div>
    </>
  );
};

export default MinimalLayout;
